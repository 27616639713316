import { Autocomplete, Checkbox, Chip, TextField, UseAutocompleteProps, Popper, useMediaQuery} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ClearOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { format, parseISO, startOfDay } from "date-fns";
import { fi } from "date-fns/locale";
import { formatDayOrMonth } from "../../utils/date-format"
import { Spacer } from "../spacer";
import { Tapahtuma, Tilaisuus, organization } from "./types";
import { genIndividualName, genTilaisuusLongLocation, getThemeTags, sortTilaisuusChronologically, SpecialThemeTag, SpecialThemeTagValue } from "./utils";
import { useFavouriteCtx } from "./favourites";
import { themeLabels } from './utils'
/** In what order are filtering results sorted */
export enum ProggisFilterOrder{
    Special,
    DateLocationTime,
}


export interface ProggisFiltersProps{
    data        : Tapahtuma;
    onFiltered  : (list: Tilaisuus[], order:ProggisFilterOrder)=>void;
    wsq: Function;
    showHide: any;
    showFavourites?: boolean;
}

export const ProggisFilters = ({data, onFiltered, showHide, showFavourites}: ProggisFiltersProps)=>{
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [selectedHours, setSelectedHours] = useState<string[]>([]);
    const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
    const [hitCount, setHitCount] = useState(0);
    const favouritesCtx = useFavouriteCtx();

    const {locations, people, days, themes, organizations, hours} = useMemo(()=>{
        const locationsSet  = new Set<string>();
        const peopleSet     = new Set<string>();
        const daysSet       = new Set<number>();
        const themeSet      = new Set<string>([]); // muu is special theme
        const organizationSet = new Set<string>([]);    
        const hoursSet = new Set<number>();


        // Process data and generate filters. 
        data.Tilaisuudet.forEach(event=>{
            // Add unique places
            const loc = genTilaisuusLongLocation(event);
            if(loc && !locationsSet.has(loc))
                locationsSet.add(loc);

            // Add unique users
            event["Tilaisuuden henkilöt"]?.forEach(person=>{
                const name = genIndividualName(person);
                if(name && !peopleSet.has(name))
                    peopleSet.add(name);
            });

            event["Tilaisuuden järjestäjät"]?.filter(x => Boolean(x.Nimi)).forEach(o => organizationSet.add(o.Nimi));

            // Add unique themes
            if(event.Sisalto){
                const eventTags = getThemeTags(event.Sisalto);

                eventTags.forEach(tag=> themeSet.add(tag));
            }

            // Add event dates
            const d = parseISO(event["Tilaisuuden alkuaika"]);
            const date = startOfDay(d).getTime();
            hoursSet.add(d.getHours());

            daysSet.add(new Date(date).getTime());
        });

        // Sort results
        const locations = [...locationsSet.keys()].sort((a,b)=>a.localeCompare(b));
        const people = [...peopleSet.keys()].sort((a,b)=>a.localeCompare(b));
        const days = [...daysSet.keys()].sort((a,b)=> a - b ).map(timestamp =>  format(new Date(timestamp), "cccc d.M", {locale: fi}));
        const themes = [...themeSet.keys()].sort((a,b)=>a.localeCompare(b)).filter(x => x !== SpecialThemeTag);
        const organizations = [... organizationSet].sort((a,b)=>a.localeCompare(b));
        const hours = [... hoursSet].map(x => `${formatDayOrMonth(x)}:00`).sort();
        // Add special theme tag to the end
        
        themes.push(SpecialThemeTag);

        // Return results
        return {
            locations,
            people, 
            days,
            themes,
            organizations,
            hours
        };
    }, [data]);


    // Call filtering when data or selections change
    useEffect(()=>filterData(),[data, selectedPeople, selectedLocations, selectedDays, selectedThemes, selectedOrganizations, selectedHours, showFavourites]);

    // Filter the data and provide it upstream
    const filterData = ()=>{
        // Are there any filters set
        const noFiltersSet = 
            selectedPeople.length == 0 &&
            selectedLocations.length == 0 &&
            selectedDays.length == 0 &&
            selectedThemes.length == 0 &&
            selectedOrganizations.length == 0 &&
            selectedHours.length == 0
        ;

        // In what order are the results
        let datarOrdering: ProggisFilterOrder;
        const orgs = new Set(selectedOrganizations);
        const hs = new Set(selectedHours.map(h=>parseInt(h.split(":")[0])));
        const ds = new Set(selectedDays);

        // Gather filtering result
        let filteredData = 
            data.Tilaisuudet
            .filter(tilaisuus=>{

                if(showFavourites && !favouritesCtx.has(tilaisuus)){
                    return;
                }

                if(noFiltersSet) {
                    return true;
                }

                const byTime = filterByTime(ds, hs, tilaisuus);
                const byPeople = filterByPeople(selectedPeople, tilaisuus)
                const byTheme = filterByTheme(selectedThemes, tilaisuus);
                const byLocation = filterByLocation(selectedLocations, tilaisuus);
                const byOrganizations = filterByOrganizations(selectedOrganizations, orgs, tilaisuus);

                return [byTime, byPeople, byTheme, byLocation, byOrganizations].filter(x => x === true || x === false).every(Boolean);
            });
        
        // Sort the filtered results
        if(noFiltersSet){
            // If no filters is set, use special ordering
            datarOrdering = ProggisFilterOrder.Special;
            filteredData = filteredData.sort(specialSorter);
        }
        else
        {
            // Filters set, use date-location-time ordering
            filteredData = filteredData.sort(tilaisuusSorter)
            datarOrdering = ProggisFilterOrder.DateLocationTime;
        }

        setHitCount(filteredData.length);
        onFiltered(filteredData, datarOrdering);
    }


    // Function to handle filtering and throttle it some
    const applyFilters = (setter: (data: any)=>void, data:any)=>{
        setter(data);
    }

    return(
        <FiltersContainer className="flex flex-col">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-10 lg:w-1/2">
                <div>
                    <b>VALITSE PÄIVÄT</b>
                    <Spacer size={8}/>
                    <FilterCheckboxDropdown
                        options={days}
                        placeholder="Valitse päivät"
                        value={selectedDays}
                        onChange={(e, value) => applyFilters(setSelectedDays, value)}
                    />
                </div>
                <div>
                    <b>KELLONAIKA</b>
                    <Spacer size={8}/>
                    <FilterCheckboxDropdown
                        options={hours}
                        placeholder="Valitse aika"
                        value={selectedHours}
                        onChange={(e, value) => applyFilters(setSelectedHours, value)}
                />
                </div>
            </div>
 
            <Spacer size={24}/>

            <b>TEEMAT</b>
            <Spacer size={8}/>
            <FilterCheckboxDropdown
                options={themes}
                placeholder="Valitse teemat"
                value={selectedThemes}
                onChange={(e, value)=>applyFilters(setSelectedThemes, value)}
                getOptionLabel={themeTagToLabel}
            />
            <Spacer size={24}/>

            <b>LAVAT / TAPAHTUMAPAIKAT</b>
            <Spacer size={8}/>
            <FilterCheckboxDropdown
                options={locations}
                placeholder="Valitse tapahtumapaikat"
                value={selectedLocations}
                onChange={(e, value)=>applyFilters(setSelectedLocations, value)}
            />
            <Spacer size={24}/>

            <b>TILAISUUDEN HENKILÖT</b>
            <Spacer size={8}/>
            <FilterCheckboxDropdown
                options={people}
                placeholder="Henkilöiden sanahaku"
                value={selectedPeople}
                onChange={(e, value)=>applyFilters(setSelectedPeople, value)}
            />
            <Spacer size={24}/>
            <b>JÄRJESTÄVÄT ORGANISAATIOT</b>
            <Spacer size={8}/>
            <FilterCheckboxDropdown
                options={organizations}
                placeholder="Valitse organisaatiot"
                value={selectedOrganizations}
                onChange={(e, value)=>applyFilters(setSelectedOrganizations, value)}
            />
            <Spacer size={24}/>
            <div>
                <h3 className="inline">OSUMIA: {hitCount} KPL</h3><span className="inline cursor-pointer float-right mx-auto md:mx-0 mt-4 md:mt-0" onClick={showHide}>Sulje <ClearOutlined/></span>
            </div>
        </FiltersContainer>
    )
}
const filterByTime = (ds, hs, tilaisuus) => {
               // Filter by date
                // NOTE: filter tilaisuus out if date does not match
                if(ds.size > 0 || hs.size > 0){
                    const d = new Date(parseISO(tilaisuus["Tilaisuuden alkuaika"]).getTime());
                    const f = format(new Date(d), "cccc d.M", {locale: fi});
                  
                    if(( ds.size > 0 && hs.size > 0)) {
                        if(!(ds.has(f) &&  hs.has( d.getHours()))) {
                            return false;
                        }
                    }

                    if(ds.size > 0 && !ds.has(f)) {
                        return false;
                    }

                    if(hs.size > 0 && !hs.has( d.getHours())) {
                        return false;
                    }
    
                    return true;
                }
}
const filterByPeople = (selectedPeople, tilaisuus) => {
    if(selectedPeople.length > 0){
        const foundHkö = tilaisuus["Tilaisuuden henkilöt"]?.find(tilaisuusHlö=>{
            if(selectedPeople.find(selectedHlö=>selectedHlö == genIndividualName(tilaisuusHlö)))
                return true;
        });

        if(foundHkö == null)
            return false;
        
        return true;
    }

}
const filterByTheme = (selectedThemes, tilaisuus) => {
    if(selectedThemes.length > 0){
        const tilaisuusThemes = getThemeTags(tilaisuus.Sisalto);
        const foundTheme = selectedThemes.find(selectedTheme=>tilaisuusThemes.find(tag=>tag == selectedTheme));
        
        // Theme not found. Check for special theme tag that matches if no themes tags excist
        if(foundTheme == null){
            if(tilaisuusThemes.length != 0)
                return false;
            
            if(selectedThemes.find(t=>t==SpecialThemeTag) == null)
                return false;
        }
        
        return true;
    }
}
const filterByLocation = (selectedLocations, tilaisuus) => {
    if(selectedLocations.length > 0){
        const foundLoc = selectedLocations.find(selectedLoc=>selectedLoc == genTilaisuusLongLocation(tilaisuus))

        if(foundLoc == null)
            return false;
        
        return true;
    }

}
const filterByOrganizations = (selectedOrganizations, orgs, tilaisuus) => {
    if(selectedOrganizations.length > 0) {
        const found = tilaisuus["Tilaisuuden järjestäjät"].some(x => orgs.has(x.Nimi));

        if(!found) {
            return false;
        }

        return true;
    }
}
export interface DateSelectionProps{
    options     : number[];
    value       : number[];
    onChange    : (dates:number[])=>void;
}

export const DateSelection = ({options, value, onChange}: DateSelectionProps)=>{

    const handleClick = (date: number, isSelected: boolean)=>{
        let newValues = [...value];
        if(isSelected)
            newValues = newValues.filter(d=>d!=date);
        else
            newValues.push(date);
        
        onChange(newValues);
    }

    return (
        <div className="flex flex-wrap" style={{columnGap: "8px", rowGap: "8px"}}>
            {options.map(d=>{
                const isSelected = !!value.find(v=>v==d);
                return (
                    <Chip
                        key={d}
                        label={format(d, "cccc d.M", {locale: fi})}
                        variant={isSelected ? "filled" : "outlined"}
                        onClick={()=>handleClick(d, isSelected)}
                        sx={[
                            { "&:hover": {background: "lightgray !important"} },
                            { "&":{
                                borderWidth: 0,
                                background: isSelected ? "white" : "gray",
                                textTransform: "capitalize",
                                borderColor: "var(--theme-color)"
                            }}
                        ]}
                    />
                )}
            )}
        </div>
    )
}

export const FiltersContainer = styled.div`
`;

type SimpleAutoCompleteProps = UseAutocompleteProps<string, true, undefined, undefined>;

export interface FilterCheckboxDropdownProps{
    options         : string[];
    placeholder     : string;
    value           : string[];
    onChange        : SimpleAutoCompleteProps["onChange"];
    getOptionLabel? : SimpleAutoCompleteProps["getOptionLabel"];
}

// export type FilterCheckboxDropdownProps = AutocompleteProps<string[], undefined, undefined, undefined>;


export const FilterCheckboxDropdown = ({placeholder, ...props}: FilterCheckboxDropdownProps)=>{
    const [inputValue, setInputValue] = useState("");
    const {value, getOptionLabel} = props;

    return (
      <Autocomplete
        {...props}
        multiple
        PopperComponent={CustomPopper}
        inputValue={inputValue}
        onInputChange={(e, value)=>setInputValue(value)}
        noOptionsText="Ei tuloksia"
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{width:'100%'}}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {getOptionLabel ? getOptionLabel(option) : option}
          </li>
        )}

        renderInput={(params) => (
            <TextField
                {...params}
                
                label={value.length || inputValue ? "" : placeholder}
                InputLabelProps={{shrink: false}}
                style={{width:'100%'}}
            />
        )}
        sx={{background: "white", borderRadius: "4px"}}
      />
    );
}



const CustomPopper = (props) =>  useMediaQuery("@media (max-width: 1024px)") ? <Popper {...props} style = {{ height: "1rem", width: '91.666667%'} } placement='bottom-start'/> : <Popper  {...props} /> ;


// Sort tilaisuus first by date tehn location and then time
export function tilaisuusSorter(a: Tilaisuus, b:Tilaisuus){
    const aTime = parseISO(a["Tilaisuuden alkuaika"]);
    const bTime = parseISO(b["Tilaisuuden alkuaika"]);

    const aDayStart = startOfDay(aTime).getTime();
    const bDayStart = startOfDay(bTime).getTime();

    if(aDayStart != bDayStart)
        return aDayStart - bDayStart;


    const aLoc = a?.Sijainti?.Paikka;
    const bLoc = b?.Sijainti?.Paikka;

    if(aLoc != bLoc){
        if(aLoc == null)
            return 1;
        if(bLoc == null)
            return -1;
        
        return aLoc.localeCompare(bLoc);
    }


    return aTime.getTime() - bTime.getTime();
}

/** Sort special events fitst, and rest in random order */
export function specialSorter(a: Tilaisuus, b:Tilaisuus){
    // Special events to show fist. Add tilaisuus UUIDs to the list below
    const specialList = [
        "0816E33E-B832-4895-89FA-81628AEED778",
        "3CD469E1-E0C9-4298-A74D-B1419E459D7E",
        "6BF87A16-6CCD-44CB-A14B-CB7EEB56EB23",
        "CE6B4308-1CC3-4083-A513-FD2DCCA073E3",
        "B0331823-AF7E-415E-948E-FE5811AFEF3B",
        "253D6B25-0272-4EB7-A35C-A82329BA2DBD",
        "D5D133C4-70F9-4E61-B1F2-2E6EAD7EABD5",
        "FA60B59A-3FAF-4E19-8874-60AF582377DF",
        "941477A1-6B4C-4B6C-B581-FF5CF0BCBA89",
        "31588719-A678-4FD9-A7A7-75004C7964BC",
        "AAA20D12-F90B-4C0A-BE9F-75CFBA1473BD",
        "E7B23CAB-940F-46DE-BD22-91B12310AE5A",
        "9DA1ACD1-A614-4DB6-8CB5-BC98EE2944F8",
    ];

    const aIsSpecial = specialList.findIndex(s=>s == a.UUID);
    const bIsSpecial = specialList.findIndex(s=>s == b.UUID);

    // both are special, calculate which comes first
    if(aIsSpecial != -1 && bIsSpecial != -1)
        return aIsSpecial - bIsSpecial;

    // a is special, b is not -> a before b
    if(aIsSpecial != -1)
        return -1;

    // b is special, a is not -> b before a
    if(bIsSpecial != -1)
        return 1;
    

    // both are non-special, return in random order
    return Math.random()*2 -1;
}

/** Convert theme tags to user friendly version */
export function themeTagToLabel(tag: string){
    return themeLabels.get(tag) ?? SpecialThemeTagValue;
}