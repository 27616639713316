import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useQueryParam, StringParam } from 'use-query-params';
import styled from "styled-components";
import { EastOutlined, EventOutlined, PersonOutlined, PinDropOutlined, ErrorOutline, ExpandLessOutlined } from '@mui/icons-material';
import { Tapahtuma, Tilaisuus } from "./types";
import { format, parseISO, startOfDay } from "date-fns";
import { fi } from "date-fns/locale";
import { Button, CircularProgress, Collapse, Dialog, Switch, useMediaQuery } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SwitchProps } from '@mui/material/Switch';
import IOSSwitch from "./ios-switch";
import { TilaisuusInfo } from "./tilaisuus";
import { RoundedButton } from "../button";
import { fetchTapahtuma } from "./fetch-data";
import { LoadingSpinner } from "../../utils/loading-spinner";
import { Spacer } from "../spacer";
import { ProggisFilterOrder, ProggisFilters } from "./filters";
import { PAGES_INFO } from "../../utils/pages-info";
import { Empty } from "../empty"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PrintIcon from '@mui/icons-material/Print';
import StarRateIcon from '@mui/icons-material/StarRate';
import { AnyARecord } from "dns";
import { FavouritesButton, RoundedButtonBase } from "./favourites";
import { BlueBorderedRoundButton, createCalendarLink } from './BlueBorderedRoundButton'
import { getEventParams } from "./utils";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { navigate } from "gatsby";


export const TapahtumaContainer = styled("div")`
    row-gap: 18px;
`;

const FilterBoxContainer = styled.div`
    height: 44px;   
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
  
@media screen and (max-width: 960px) {
    flex-direction: column;

    div:nth-child(1) { order: 1; width:100%; }
    div:nth-child(2) { 
        order: 2;
        height: 44.px;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        align-items: center;
        p {
            margin-top:0.25rem;
        }
    }
    div:nth-child(3) { order: 4; }
    div:nth-child(4) { order: 3; width:100%; margin-top:0.25rem; }

    width: 100%;
}
`;
const NoResult = () => (
    <div className="w-full flex flex-col items-center">
        <div className="h-40 mb-8">
            <ErrorOutlineIcon sx={{ width: '100%', height: '100%' }} />
        </div>
        <h3 className="uppercase">Hakusi ei tuottanut tulosta</h3>
    </div>
);

export interface ProggisMetaInfo {
    title: string;
    description: string;
    url?: string;
}

export interface ProggisElementProps {
    uuid: string;
    tilaisuus?: string;
    setMetaInfo: (metaInfo?: ProggisMetaInfo) => void;
}

export const ProggisElement = ({ uuid, tilaisuus: queryTilaisuus, setMetaInfo }: ProggisElementProps) => {
    const showIncrement = 4;

    const scrollTarget = useRef(null);

    const [data, setData] = useState<Tapahtuma>();
    const [ordering, setOrdering] = useState<ProggisFilterOrder>();
    const [error, setError] = useState<any>();
    const [selectedTilaisuus, setSelectedTilaisuus] = useState<Tilaisuus>();
    const [showCount, setShowCount] = useState(4000);

    const [sourceData, setSourceData] = useState<Tilaisuus[]>([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showFavourites, setShowFavourites] = useState(false);

    const fetching = data == null && error == null;
    const dataLength = data?.Tilaisuudet?.length ?? 0; // How many tilaisuus there are in src original data

    // Set tilaisuus as active. opens event popup,
    const selectTilaisuus = (tilaisuus: Tilaisuus | undefined) => {
        const url = `${PAGES_INFO.suomiAreenaPoriOhjelmaPage.path}/${tilaisuus?.UUID}`;
        // Propagate selected tilaisuus infos up
        let metaInfo: ProggisMetaInfo | undefined;
        if (tilaisuus) {
            metaInfo = {
                title: tilaisuus.Otsikko ?? "",
                description: tilaisuus.Sisalto?.slice(0, 300) ?? "",
                url: url,
            };
        }

        setMetaInfo(metaInfo);
        setSelectedTilaisuus(tilaisuus);
    }

    // Effect when 'queryTilaisuus' changes
    //  Loads Tilaisuus data and opens selected event
    useEffect(() => {
        const aborter = new AbortController();

        // Return cached event data or fetch new data
        (async ()=>{
            if(data) return data;
            
            // No data cached, fetch
            const result = await fetchTapahtuma(uuid, {signal: aborter.signal});
            setData(result);
            return result;
        })() // Call the async function immediately
            .then(res => {
                // Check that page has not been closed
                if (aborter.signal.aborted) return;

                let eventToOpen: Tilaisuus|undefined = undefined;

                // If query parameter is set, try to open it
                if (queryTilaisuus) {
                    eventToOpen = res.Tilaisuudet.find(e => e.UUID === queryTilaisuus);

                    // Handle edge case where event is not found
                    if(!eventToOpen){
                        console.warn("Could not find requested event 'queryTilaisuus'");
                        openInfo(null, true); // Open events page, replacing current history entry
                        return;
                    }
                }

                // Make event active
                selectTilaisuus(eventToOpen);
            })
            .catch(err => {
                console.error("Proggis fetch error:", err);
                if (aborter.signal.aborted) return;

                setError(err);
            })
        ;

        // Effect unmount, abort fetch
        return () => aborter.abort();
    }, [queryTilaisuus]);

    const handleFiltered = (data: Tilaisuus[], ordering: ProggisFilterOrder) => {
        setOrdering(ordering);
        setSourceData(data);
    }

    // Open tilaisuus info, navigates to event page
    const openInfo = (tilaisuus: Tilaisuus|null, replace?: boolean) => {
        let url = PAGES_INFO.suomiAreenaPoriOhjelmaPage.path;
        let state: any = {openInfo: true};

        if(tilaisuus)
            url += "/" + tilaisuus.UUID;
        
        // If closing popup (tilaisuus == null) and
        //  popup was opened with this function (history.state.openInfo == true)
        //  go back to previous history entry instead of navigating to new page
        // This is to prevent 'scroll to top' behaviour when closing popup
        if(!tilaisuus && history.state?.["openInfo"] == true){
            return navigate(-1);
        }

        navigate(url, {replace, state});
    }

    const increaseShown = () => setShowCount(v => v + showIncrement);


    const listingElemens = useMemo(() => {

        if (error || data == null) return [];
        const now = new Date();

        let lastDay = 0;
        let lastLoc = null;
        let inPast = false;
        let inFuture = false;
        let inStage = false;

        const inStageList: JSX.Element[] = []
        const inFutureList: JSX.Element[] = []
        const isPresentedList: JSX.Element[] = []
        const resultElements: JSX.Element[] = [];
        // Create listing elements and separator headers
        for (const e of sourceData) {

            const { entryDayStart, isInFuture, isInStage, isHappened } = getEventParams(now, e);
            const element = (<TapahtumaListing key={e.UUID} tilaisuus={e} openInfo={openInfo} />)

            // In 'DateLocationTime' ordering add section heading fields
            if (ordering == ProggisFilterOrder.DateLocationTime) {

                if (!inFuture && inFuture != isInFuture) {
                    inFuture = true;
                    resultElements.push(<h2 key={"future-header-" + e.UUID} className="">Tulevat</h2>)
                }

                if (!inPast && inPast != isHappened) {
                    inPast = true;
                    resultElements.push(<h2 key={"past-header-" + e.UUID} className="">Päättyneet</h2>)
                }
                // If date changed, add date heading
                if (lastDay < entryDayStart) {
                    lastDay = entryDayStart;
                    lastLoc = null;

                    resultElements.push(<h3 key={"day-" + e.UUID} className="py-4" style={{ fontWeight: 600 }}>{format(entryDayStart, "cccc dd.MM.yyyy", { locale: fi })}</h3>)
                }

                // If location changed, add location heading
                if (lastLoc != e?.Sijainti?.Paikka) {
                    lastLoc = e?.Sijainti?.Paikka;

                    resultElements.push(<h3 className="font-semibold my-4" key={"loc-" + e.UUID}><PinDropOutlined className="mr-2" />{lastLoc}</h3>)
                }
                resultElements.push(element);
            } else {
                if (isInStage) {
                    inStageList.push(element)
                }
                if (isInFuture) {
                    inFutureList.push(element)
                }
                if (isHappened) {
                    isPresentedList.push(element);
                }
            }
        }

        if (ordering == ProggisFilterOrder.DateLocationTime) {
            if (resultElements.length == 0) {
                resultElements.unshift(<NoResult key={"no-result"} />)
            }
            return resultElements;
        }

        if (inStageList.length > 0) {
            inStageList.unshift((<h2 key={"in-stage-header"} className="">Käynnissä</h2>));
        }

        if (inStageList.length === 0) {
            // inStageList.push((<p key="no-events-in-stage-announcement" className="uppercase text-center mb-12 font-light font-3xl">Ei uusia tapahtumia</p>));
        }
        if (inFutureList.length > 0) {
            inFutureList.unshift((<h2 key={"future-header"} className="">Tulevat</h2>));
        }
        if (isPresentedList.length > 0) {
            isPresentedList.unshift((<h2 key={"past-header"} className="">Päättyneet</h2>));
        }

        return [...inStageList, ...inFutureList, ...isPresentedList]

    }, [sourceData, showCount]);

    const dialogFullscreen = useMediaQuery("@media (max-width: 500px)");
    const timeNow = new Date();
    const noItems = false //listingElemens.length === 0;

    return (
        <>
            <div className="flex flex-col md:flex-row items-center" style={{columnGap: "24px"}}>
                <h2 className="w-full md:w-auto flex-1 mb-4 md:mb-0" ref={scrollTarget}>SUOMIAREENA 2024 OHJELMA</h2>
            </div>
            {dataLength > 0 &&

                <FilterContainer>
                    <FilterBoxContainer className="">
                        <FilterButton
                            className="w-full h-full"
                            variant={showFilters ? "contained" : "outlined"}
                            disableRipple
                            onClick={() => setShowFilters(p => !p)}
                            sx={[
                                {
                                  '&:hover': {
                                    backgroundColor: showFilters ? 'white' : "var(--theme-blue)",
                                  },
                                }]}
                        >
                            <FilterAltIcon className="mr-2 my-1" /> <span className="my-2">Rajaa hakua</span> {(dataLength != sourceData.length || showFilters) && `(${sourceData.length})`}
                        </FilterButton>
                    </FilterBoxContainer>
                    <FilterBoxContainer className="w-fit m-auto">
                        <StarRateIcon className="mr-2" /> <p className="font-bold lg:w-full my-auto text-base h-12 inline"> Näytä suosikit </p><IOSSwitch className="inline ml-2" checked={showFavourites} onChange={e => setShowFavourites(!showFavourites)} />
                    </FilterBoxContainer>
                    <FilterBoxContainer className="w-fit m-auto ">
                        <p className="w-full my-2 lg:ml-30 xl:ml-40 text-base">Tilaisuuksien kokonaismäärä: {dataLength} kpl</p>
                    </FilterBoxContainer>

                    <FilterBoxContainer className="lg:ml-auto md:w-fit">
                        <PrintButton
                            className="w-full"
                            variant={"outlined"}
                            tapahtuma={data!}
                            tilaisuudet={sourceData}
                            buttonText={showFavourites ? "TULOSTA suosikit" : "TULOSTA OHJELMA"}
                        />
                    </FilterBoxContainer>
                </FilterContainer>
            }
            {
                fetching && <LoadingSpinner loaded={false} />
                ||
                error && <FetchError />
                ||
                <>

                    <Collapse in={showFilters}>
                        <ProggisFilters data={data} onFiltered={handleFiltered} showHide={() => setShowFilters(p => !p)} showFavourites={showFavourites} />
                        <Spacer size={32} />

                        <hr style={{ color: "white" }} />
                    </Collapse>
                    {noItems ? <FilterEmpty /> : (
                        <TapahtumaContainer className="flex flex-col">
                            {listingElemens.length > 0 &&
                                <>
                                    {listingElemens}
                                    <BackToTopBtn target={scrollTarget} />
                                </>

                            }
                        </TapahtumaContainer>
                    )}
                    {/** Show next button only if more is available */}
                    {showCount < sourceData.length &&
                        <RoundedButton className="mx-auto" onClick={increaseShown}>NÄYTÄ LISÄÄ</RoundedButton>
                    }


                    {selectedTilaisuus != null &&
                        <Dialog maxWidth="lg" fullScreen={dialogFullscreen} fullWidth={true} open={true} onClose={() => openInfo(null)} scroll="body">
                            <TapahtumaContainer className="flex flex-col min-h-full">
                                <TilaisuusInfo tilaisuus={selectedTilaisuus} close={() => openInfo(null)} />
                            </TapahtumaContainer>
                        </Dialog>
                    }
                </>
            }
        </>
    )
}

export const FetchError = () => <ListError
    title="TAPAHTUMAN TIETOJA EI VOITU LADATA"
    info="Yritä myöhemmin uudestaan"
/>

export const FilterEmpty = () => <ListError
    title="HAKUSI EI TUOTTANUT TULOSTA"
    info="Kokeile eri avainsanoja, yleisempiä tai vähemmän avainsanoja."
/>

export interface ListErrorProps {
    title: string;
    info: string;
}

export const ListError = ({ title, info }: ListErrorProps) => {
    return (
        <div className="flex flex-col items-center">
            <ErrorOutline style={{ height: "140px", width: "140px" }} />
            <Spacer size={32} />

            <div style={{ fontSize: "30px", fontWeight: 700 }}>{title}</div>

            <p>{info}</p>
        </div>
    )
}


export const ListingHeading: React.FC = styled("h3")`
    font-weight: 700;
    color: black;
    margin-bottom: 16px;
`;


export interface ProggisContainerProps {
    isOld?: boolean;
}

export const ProggisContainer = styled("div") <ProggisContainerProps>`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 24px;
    background-color: white;
    color: black;
    border-radius: 4px;

    /** Special styles if this is old elemnt */
    ${(props) => props.isOld && `
        background-color: rgba(0,0,0,.2);
        & * {
            color: lightgray;
        }
    `}
`;


export const ListingInfoEntry = styled("span")`
    display: flex;

    margin-right: 24px;
    padding-left: 24px;
    
    & > *:first-child{
        margin-left: -24px;
        margin-right: 4px;
        margin-top: 2px;
    }

    &:last-of-type{
        margin-right: 0;
    }
`

export const ListingInfoContainer = styled("div")`
    display: flex;
    color: #444444;
    flex-wrap: wrap;
    row-gap: 8px;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;


export const ListingArrow = () => <EastOutlined style={{ marginLeft: "auto", color: "white" }} />;


export interface TapahtumaListingProps {
    tilaisuus: Tilaisuus;
    openInfo?: (tilaisuus: Tilaisuus) => void;
}

/** Single tapahtuma  */
export const TapahtumaListing = ({ tilaisuus, openInfo }: TapahtumaListingProps) => {

    const eventOrganizerString = tilaisuus["Tilaisuuden järjestäjät"]?.map(e => e.Nimi).join(", ");

    const now = new Date();
    // Generate event date string. This assumes that end date is during same day as begining
    const eventDateStart = parseISO(tilaisuus["Tilaisuuden alkuaika"]);
    const eventDateEnd = parseISO(tilaisuus["Tilaisuuden loppuaika"]);
    const eventDateFormatted = format(eventDateStart, "dd.MM.yyyy 'klo' HH:mm") + "-" + format(eventDateEnd, "HH:mm");

    const isOld = eventDateEnd < now;

    return (
        <ProggisContainer className="cursor-pointer" onClick={() => openInfo(tilaisuus)} isOld={isOld}>
            {/** span-nowrap-nbsp trick to prevent arrow being moved to different line */}
            <ListingHeading>{tilaisuus.Otsikko}</ListingHeading>

            <ListingInfoContainer>
                {/** Info about Organizers */}
                <ListingInfoEntry>
                    <PersonOutlined />
                    <span>{eventOrganizerString}</span>
                </ListingInfoEntry>

                {/** Info about event dates */}
                <ListingInfoEntry>
                    <EventOutlined />
                    <span>{eventDateFormatted}</span>
                </ListingInfoEntry>

                {/** Info about event location */}
                <ListingInfoEntry>
                    <PinDropOutlined />
                    <span>{tilaisuus?.Sijainti?.Paikka}</span>
                </ListingInfoEntry>
            </ListingInfoContainer>
            <div>

            </div>

            <Spacer size={16} />
            <ButtonContainer>
                <div>
                    <FavouritesButton tilaisuus={tilaisuus} />
                </div>
                <div>
                    <BlueBorderedRoundButton whiteBorders={isOld} onClick={createCalendarLink(tilaisuus)}><div className="ml-auto"><CalendarTodayIcon/> Lisää omaan kalenteriin&nbsp;</div></BlueBorderedRoundButton>
                </div>
                <div>
                    <BluedRoundButton><div className="ml-auto">Katso puhujat&nbsp;<ListingArrow /></div></BluedRoundButton>
                </div>
            </ButtonContainer>


        </ProggisContainer>
    )
}

const ButtonContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
flex-direction: row;
    div:nth-child(2) {
        display:inline;
        margin-left:0.5rem;
        margin-right:0.5rem;

    }

    div:nth-child(3){
        margin-left:auto;   
        display:inline-block;
    }
  
@media screen and (max-width: 877px) {
    display: flex;
    width:294.5;
    align-items: center;
    flex-direction: column;
    div {
        width:100%;
    }
    div:nth-child(1) {
        button {
            width: 100%;
        }
    }

    div:nth-child(2) {
        display:inline;
        margin-top:0.5rem;
        margin-bottom:2rem;
        button {
            width: 100%;
        }
    }

    div:nth-child(3) {
        display:inline;
        margin-left:0;
        width:100%;
        button {
            width: 100%;
        }
    }
}
`;

const BluedRoundButton = styled(RoundedButtonBase)`
    height:52;
    display: inline-flex;
    align-items: center;
    gap: 0.1em;
    border: 2px solid transparent;
    background-color:var(--theme-blue);
    color:white;
    text-transform: uppercase;
`;

export const StyledPrintButton = styled(Button)`
    &.MuiButton-root {
        color: white;
    }

    &.MuiButton-outlined {
        border-color: white;
    }
`

export type PrintButtonProps = {
    tapahtuma: Tapahtuma;
    tilaisuudet: Tilaisuus[];
    buttonText: string;
} & Omit<Parameters<typeof Button>[0], "children">;
export const PrintButton = ({ tapahtuma, tilaisuudet, buttonText, onClick, disabled, ...rest }: PrintButtonProps) => {
    const [downloading, setDownloading] = useState(false);

    const handlePrint = async (e) => {
        try {
            setDownloading(true);
            const { printEvents } = await import("./print/printer");
            await printEvents(tapahtuma, tilaisuudet, "Suomiareena tapahtumat 2024.pdf");
            onClick?.(e);
        }
        finally {
            setDownloading(false);
        }
    }

    return (
        <StyledPrintButton
            onClick={handlePrint}
            disabled={downloading || disabled}
            {...rest}
        >
            <span className="mr-4 my-1 h-6" >
                {downloading ? <CircularProgress size="24px" /> : <PrintIcon />}
            </span>
            {buttonText}
        </StyledPrintButton>
    );
}

export const FilterButton = styled(Button)`
    &.MuiButton-root {
        color: white;
        font-weight:bold;
        background-color: var(--theme-blue);
    }

    &.MuiButton-outlined {
        border-color: white;
    }

    &.MuiButton-contained {
        color: var(--color-purple);
        background-color: white;
    }
`

export const BackToTopContainer = styled("div")`
    position: sticky;
    width: 100%;
    bottom: 15px;
    display: flex;
    justify-content: end;
`

export const StyledBackToTopBtn = styled(Button)`
    &.MuiButton-root{
        border-radius: 5em;
    }

    &.MuiButton-root:hover{
        background: white;
    }

    &.MuiButton-contained{
        border: 1px solid var(--color-purple);
        background: white;
        color: var(--color-purple);
    }
`

export interface BackToTopBtnProps {
    target?: React.RefObject<HTMLElement>;
}

export const BackToTopBtn = ({ target }: BackToTopBtnProps) => {
    const handleClick = () => {
        if (target.current)
            target.current.scrollIntoView({ behavior: "smooth" });
        else
            window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <BackToTopContainer>
            <StyledBackToTopBtn
                variant="contained"
                onClick={handleClick}
            >
                <ExpandLessOutlined />
                TAKAISIN YLÖS
            </StyledBackToTopBtn>
        </BackToTopContainer>
    );
}