import React, { useMemo } from "react";
import { ClearOutlined, EventOutlined, PinDropOutlined } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { ListingHeading, ListingInfoContainer, ListingInfoEntry, ProggisContainer } from "./tapahtuma";
import { Henkilö, Tilaisuus } from "./types";
import styled from "styled-components";
import DefaultUserImage from "../../images/default-user-picture.png";
import { BlueBorderedRoundButton, createCalendarLink } from './BlueBorderedRoundButton'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Spacer } from "../spacer";
import ShareButtons from "../social/share-component";
import { formatSisältöText, genTilaisuusLongLocation } from "./utils";

export interface TilaisuusInfoProps{
    tilaisuus   : Tilaisuus;
    close       : ()=>void;
}

export const TilaisuusInfo = ({tilaisuus, close}:TilaisuusInfoProps)=>{
    const eventOrganizerString = tilaisuus["Tilaisuuden järjestäjät"]?.map(e=>e.Nimi).join(", ");

    // Generate event date string. This assumes that end date is during same day as begining
    const eventDateStart = parseISO(tilaisuus["Tilaisuuden alkuaika"]);
    const eventDateEnd = parseISO(tilaisuus["Tilaisuuden loppuaika"]);
    const eventDateFormatted = format(eventDateStart, "dd.MM.yyyy 'klo' HH:mm") + "-" + format(eventDateEnd, "HH:mm");

    const sisältö = useMemo(()=>formatSisältöText(tilaisuus.Sisalto), [tilaisuus.Sisalto]);

    return (
        <ProggisContainer className="flex flex-col flex-1" style={{padding:"64px 32px 32px"}}>
            <CloseBtn className="cursor-pointer" onClick={close}><ClearOutlined/></CloseBtn>

            <ListingHeading>{tilaisuus.Otsikko}</ListingHeading>

            <ListingInfoContainer>
                {/** Info about event dates */}
                <ListingInfoEntry>
                    <EventOutlined/>
                    <span>{eventDateFormatted}</span>
                </ListingInfoEntry>

                {/** Info about event location */}
                <ListingInfoEntry>
                    <PinDropOutlined/>
                    <span>{genTilaisuusLongLocation(tilaisuus)}</span>
                </ListingInfoEntry>
            </ListingInfoContainer>
            <BlueBorderedRoundButton className="w-fit mt-8" onClick={createCalendarLink(tilaisuus)}><CalendarTodayIcon /> Lisää omaan kalenteriin</BlueBorderedRoundButton> 
            <Spacer size={32}/>

            {/** Event organizer section */}
            {eventOrganizerString && <>
                <b>Järjestäjinä</b>
                <div>{eventOrganizerString}</div>

                <Spacer size={16}/>
            </>}

            {/** Event description section */}
            {tilaisuus.Sisalto && <>
                <div className="whitespace-pre-line">{sisältö}</div>
                <Spacer size={32}/>
            </>}

            {/** Event people section */}
            {tilaisuus["Tilaisuuden henkilöt"] && tilaisuus["Tilaisuuden henkilöt"].length > 0 && <>
                <b>Puhujina</b>
                <Spacer size={20}/>

                <PuhujatContainer>
                    {tilaisuus["Tilaisuuden henkilöt"]
                        ?.sort((a, b)=>`${a.Sukunimi} ${a.Etunimi}`.localeCompare(`${b.Sukunimi} ${b.Etunimi}`, "fi"))
                        ?.map((hlo, idx)=><HenkilöPortrait key={idx} henkilö={hlo}/>)}
                </PuhujatContainer>
                
                <Spacer size={64}/>
            </>}

            <Spacer size={0} className="flex-1"/>
                    <div className="flex justify-center md:flex-row flex-col">
                        <ShareButtons className="mx-auto" shareOnSocialMedia={true} title={`SuomiAreena - ${tilaisuus.Otsikko}`}/> 
                        <div className="mx-auto md:mx-0 mt-4 md:mt-0 cursor-pointer" onClick={close}>Sulje <ClearOutlined/></div>
                    </div>

                
        </ProggisContainer>
    )
}

// export const TilaisuusSisältö = styled.div`
//     text-al
// `;

export const CloseBtn = styled("span")`
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
`;

export const CloseBtnBottom = styled("span")`
    position: absolute;
    bottom: 2.5rem;
    right: 2rem;
    cursor: pointer;
`;

export const PuhujatContainer = styled("div")`
    display: grid;
    grid-template-columns: 1fr 1fr;

    row-gap: 20px;
    column-gap: 20px;

    @media screen and (max-width: 900px){
        grid-template-columns: 1fr;
    }
`;

export const HenkilöContainer = styled("div")`
    display: flex;
    
    column-gap: 20px;

    @media (max-width: 900px){
        column-gap: 10px;
    }
`;

export const HenkilöImage = styled("img")`
    border: 2px solid var(--color-purple);
    border-radius: 100%;

    min-width: 140px;
    width: 140px;
    height: 140px;

    @media screen and (max-width: 768px){
        min-width: 100px;
        width: 100px;
        height: 100px;
    }
`;

export interface HenkilöPortraitProps{
    henkilö: Henkilö;
}

export const HenkilöPortrait = ({henkilö}: HenkilöPortraitProps)=>{

    const pictureSrc = henkilö.KuvaPieni?.[0]?.URL ?? DefaultUserImage;

    return (
        <HenkilöContainer>
            <HenkilöImage src={pictureSrc}/>
            <div className="flex flex-col justify-center">
                <b>{henkilö.Etunimi} {henkilö.Sukunimi}</b>
                <div>{henkilö.Titteli}</div>
                <div>{henkilö.Organisaatio}</div>
                <div className="text-grey">{henkilö.Rooli}</div>
            </div>
        </HenkilöContainer>
    )
}