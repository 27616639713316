import React, { createContext, useContext, useReducer, useState } from "react";
import styled, { css } from "styled-components";
import { isPast } from "date-fns";
import StarIcon from '@mui/icons-material/Star';
import { Tilaisuus } from "./types";
import { isBrowser } from "../../utils/utils";

export interface FavouritesContextArgs {
  tapahtumaUUID: string;
  store?: Storage;
}

class FavouritesContext {
  private favourites: Set<string>;
  private storeKey: string;
  private store?: Storage;

  constructor({tapahtumaUUID, store}: FavouritesContextArgs){
    this.storeKey = `Tapahtuma_${tapahtumaUUID}_Favourites`;
    this.store = store ?? (isBrowser ? localStorage : undefined);
    this.load();
  }

  getUUID(key: string | Tilaisuus){ return typeof(key) === "string" ? key : key.UUID }

  public delete(UUID: string): boolean;
  public delete(tilaisuus: Tilaisuus): boolean;
  public delete(key: string | Tilaisuus){
    const uuid = this.getUUID(key);
    const result = this.favourites.delete(uuid);
    this.save();
    return result;
  }

  public set(UUID: string): void;
  public set(tilaisuus: Tilaisuus): void;
  public set(key: string | Tilaisuus){
    const uuid = this.getUUID(key);
    this.favourites.add(uuid);
    this.save();
  }
  
  public has(UUID: string): boolean;
  public has(tilaisuus: Tilaisuus): boolean;
  public has(key: string | Tilaisuus){
    const uuid = this.getUUID(key);
    return this.favourites.has(uuid);
  }

  public save(){
    if(!this.store) return;

    const data = Array.from(this.favourites.entries()).map(([k,v])=>k);
    const json = JSON.stringify(data);
    this.store.setItem(this.storeKey, json);
  }

  public load(){
    if(!this.store) return;

    const json = this.store.getItem(this.storeKey);
    const data = json ? JSON.parse(json) : [] ;
    this.favourites = new Set(data);
  }
}

const favouritesContext = createContext<FavouritesContext>(undefined!);

export interface FavouritesProviderProps {
  tapahtumaUUID: string;
  children: JSX.Element;
}

/** Helper to access favourites context provider */
export const FavouritesProvider = ({tapahtumaUUID, children}: FavouritesProviderProps) =>
  <favouritesContext.Provider value={new FavouritesContext({tapahtumaUUID})}>{children}</favouritesContext.Provider>;

export const useFavouriteCtx = ()=>useContext(favouritesContext);

/** Helper hook to to access single tapahtumas favourite status */
export const useFavourite = (uuid: string): [boolean, (f:boolean)=>void]=>{
  const ctx = useContext(favouritesContext);
  const [isFavourited, setIsFavourited] = useState(ctx.has(uuid));

  return [
    isFavourited,
    (f: boolean)=>{
      f ? ctx.set(uuid) : ctx.delete(uuid);
      setIsFavourited(f);
    },
  ];
}


export const RoundedButtonBase = styled.button`
  padding: 0.5em 1.5em;
  border: 2px solid white;
  border-radius: 9999px;
`;

interface FavouriteButtonColors {
  backgroundColor : string;
  borderColor     : string;
  color           : string;
}

export interface StyledFavouritesButtonProps {
  isFavourited: boolean;
  isPast: boolean;
}

/** Custom styled favourites button */
export const StyledFavouritesButton = styled(RoundedButtonBase)<StyledFavouritesButtonProps>`
  display: inline-flex;
  align-items: center;
  gap: 0.1em;
  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0.3rem;
  }

  ${props => {
    const colors = getFavouriteButtonColors(props.isFavourited, props.isPast);
    return css`
      background-color    : ${colors.backgroundColor};
      border-color        : ${colors.borderColor};
      color               : ${colors.color}; 
    `}
  }
`;

/** Helper to get required css colours for favourite button */
const getFavouriteButtonColors = (isFavourited: boolean, isPast: boolean): FavouriteButtonColors =>{
/*
                    isFavourited
isPast   true                          false
true    bg:     #28000066               bg:     transparent
        border: #28000066               border: white
        color:  white                   color:  white

false   bg:     #00B2A9                 bg:     white/transparent
        border: #00B2A9                 border: #10069F
        color:  #FFFFFF                 color:  #10069F
*/

  if(isFavourited && isPast) return {
    backgroundColor : "#28000066",
    borderColor     : "transparent",
    color           : "#fff",
  }

  if(isFavourited && !isPast) return {
    backgroundColor : "#00B2A9",
    borderColor     : "#00B2A9",
    color           : "#fff",
  }

  if(!isFavourited && isPast) return {
    backgroundColor : "transparent",
    borderColor     : "#fff",
    color           : "#fff",
  }

  // This is last possible combination, but TS doesn't know that so no if here
  // if(!isFavourited && !isPast)
  return {
    backgroundColor : "transparent",
    borderColor     : "#10069F",
    color           : "#10069F",
  }
}


type FavouritesButtonProps = {
  tilaisuus: Tilaisuus;
} & Pick<JSX.IntrinsicElements["button"], "children">;

/** Custom favourites button with state stored to Tapahtuma favourites localstorage */
export const FavouritesButton = ({tilaisuus, ...rest}: FavouritesButtonProps)=>{
  const uuid = tilaisuus.UUID;
  const [isFavourited, setIsFavourite] = useFavourite(uuid);
  const isOld = isPast(tilaisuus.endTime);

  const handleClick = (e: React.MouseEvent)=>{
    e.preventDefault();
    e.stopPropagation();

    setIsFavourite(!isFavourited);
  }

  const text = isFavourited ?
    "POISTA SUOSIKEISTA" :
    "LISÄÄ SUOSIKKEIHIN"
  ;

  return (
    <StyledFavouritesButton
      onClick={handleClick}
      isFavourited={isFavourited}
      isPast={isOld}
      {...rest}
    ><div className="ml-auto"><StarIcon/>{text}</div></StyledFavouritesButton>
  );
}