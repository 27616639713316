import { Tapahtuma } from "./types";
import { parse, parseISO } from "date-fns";

const API_BASE = process.env.PROGGIS_OHJELMA_API;

/** Generate Proggis api URL */
function genApiURL(path:string){ return new URL(path, API_BASE); }

/** Convert response to json */
async function toJSON<T>(response:Response){ return response.json() as unknown as T; }

export interface APIFetchOptions{
    signal?: AbortSignal;
}

/** Fecth data from Proggis api */
async function APIFetch(url: URL, options?: APIFetchOptions){
    const init: RequestInit = {
        signal: options?.signal,
    };

    return fetch(url.toString(), init)
        .then(res=>{
            if(!res.ok) throw new Error("Response not ok");
            return res;
        })
}


/** Fetch specific tapahtuma info */
export async function fetchTapahtuma(uuid: string, options?: APIFetchOptions){
    const url = genApiURL(`tapahtumat/${uuid}`);

    return APIFetch(url, options)
        .then(res=>toJSON<Tapahtuma>(res))
        .then(processTapahtuma)
    ;
}

/** Precalculate some field values, so that they don't have to be done all the time elsewhere */
function processTapahtuma(tapahtuma: Tapahtuma){
    tapahtuma.startTime = parse(tapahtuma["Tapahtuman alku"], "yyyy-MM-dd", new Date());
    tapahtuma.endTime = parse(tapahtuma["Tapahtuman loppu"], "yyyy-MM-dd", new Date());

    tapahtuma.Tilaisuudet.forEach(tilaisuus=>{
        tilaisuus.startTime = parseISO(tilaisuus["Tilaisuuden alkuaika"]);
        tilaisuus.endTime = parseISO(tilaisuus["Tilaisuuden loppuaika"]);
    });

    return tapahtuma;
}