import React from "react";

export interface LoadingSpinnerProps{
    loaded: boolean;
}

export const LoadingSpinner = ({loaded}: LoadingSpinnerProps)=>{
    const spinnerStyle = {display: loaded ? "none" : "block"};

    return <div className="loaderSpinner" style={{...spinnerStyle}}/>
}