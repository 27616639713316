import React from "react";
import { parseISO, format, startOfDay } from "date-fns";
import { ReactElement } from "react";
import {Henkilö, Tilaisuus} from "./types";
import { ExternalLink } from "../link";
import { FreezableMap } from "./FreezableMap";

/** Generate "long name" for location */
export function genTilaisuusLongLocation(tilaisuus: Tilaisuus){
    const sijainti = tilaisuus.Sijainti;
    const osoite   = sijainti?.Osoite;

    let parts: string[] = [];

    parts.push(sijainti?.Paikka ?? "Ei tiedossa");

    if(osoite){
        
        if(osoite.Katuosoite && osoite.Katuosoite !== "*" && osoite.Katuosoite !== '-' ) {
            parts.push(osoite.Katuosoite);
        }        
        //parts.push(osoite.Postinumero);
        //parts.push(osoite.Postitoimipaikka);
    }

    return parts.join(", ");
}

export function genIndividualName(hlo: Henkilö){
    let parts: string[] = [];
    if(hlo.Etunimi && hlo.Etunimi != "-")
        parts.push(hlo.Etunimi.trim());
    if(hlo.Sukunimi && hlo.Sukunimi != "-")
        parts.push(hlo.Sukunimi.trim());
    if(parts.length == 0 && hlo.Nimi)
        parts.push(hlo.Nimi.trim());

    return parts.join(" ");
}


export function sortTilaisuusChronologically(a:Tilaisuus, b:Tilaisuus){
    return parseISO(a["Tilaisuuden alkuaika"]).getTime() - parseISO(b["Tilaisuuden alkuaika"]).getTime();
}

export const SpecialThemeTag = 'a-non-existent-level';

export const SpecialThemeTagValue = 'Muu';

const eventThemesRegEx = /^#(.*)$/mg;
const eventThemeSplitRegEx = /\s/;

export const themeLabels =(() => {
    const fm = new FreezableMap([
    ["kut", "kulttuuri ja urheilu"],
    ["terv", "terveys, mielenterveys ja hyvinvointi"],
    ["ilma","ilmasto, kestävä kehitys, energia ja vihreä siirtymä"],
    ["tek","teknologia ja tekoäly"],
    ["työ", "koulutus, työ, yrittäjyys ja kansainvälisyys"],
    ["dem", "demokratia ja sivistys"]]);
    Object.freeze(fm);
    return fm;
})();

export function getThemeTags(text?: string){
    // Find tag lines
    const eventThemesMatch = text?.match(eventThemesRegEx);

    if(!eventThemesMatch) return [];
    
    // Found match can contain multiple hashtags
    return eventThemesMatch
        // Split match to separate tags (flatten)
        .flatMap(match=>match.split(eventThemeSplitRegEx))
        // Trim each tag
        .map(theme=>theme.trim())
        // Filter out fake tags, atleast 2 char '#' + something, and starts with #
        .filter(theme=>theme.length > 2 && theme[0] == "#")
        // Remove #
        .map(tag=> themeLabels.has(tag.slice(1))? tag.slice(1) : SpecialThemeTag )
    ;
}



const linkRegEx = /(?<url>https?:\/\/.+?)(\s|$)/g;

/** Return text without ending hashtags */
export function formatSisältöText(content:string){
    if(typeof(content) !== "string")
        return null;
 
    // Remove theme tags
    content = content.replace(eventThemesRegEx, "");

    // Convert links to actual link elements
    const reactSisältö: ReactElement[] = [];

    let currentIndex = 0;
    const linkMatches = content.matchAll(linkRegEx);
    for(const match of linkMatches){
        const url = match.groups["url"];

        reactSisältö.push(
            <React.Fragment key={currentIndex}>
                <>{content.slice(currentIndex, match.index)}</>
                <ExternalLink to={url}>{url}</ExternalLink>
            </React.Fragment>
        );

        currentIndex = match.index + match.groups["url"].length;
    }

    reactSisältö.push(<React.Fragment key={currentIndex}>{content.slice(currentIndex)}</React.Fragment>);

    return reactSisältö;
}

export function formatEventDateInfo(eventDateStart: Date, eventDateEnd: Date){
    return format(eventDateStart, "dd.MM.yyyy 'klo' HH:mm") + "-" + format(eventDateEnd, "HH:mm");
}

export const getEventParams = (nowDate: Date, e: Tilaisuus) => {
    const now = nowDate.getTime();
    const entryStart = parseISO(e["Tilaisuuden alkuaika"]).getTime();
    const entryEnd = parseISO(e["Tilaisuuden loppuaika"]).getTime();
    const entryDayStart = startOfDay(entryStart).getTime();
    const isInFuture = now < entryStart;
    const isInStage = entryStart <= now && now < entryEnd;
    const isHappened = now > entryEnd;
    return {
        entryStart,
        entryEnd,
        entryDayStart,
        isInFuture,
        isInStage,
        isHappened
    };
}

