import React, { createContext, useContext, useReducer, useState } from "react";

import styled, { css } from "styled-components";
import { RoundedButtonBase } from "./favourites";
import cal from 'generate-calendar-url';

export interface BlueBorderedRoundButtonProps {
    whiteBorders?: boolean;
  }

export const BlueBorderedRoundButton = styled(RoundedButtonBase)<BlueBorderedRoundButtonProps>`
    height:52;
    display: inline-flex;
    align-items: center;
    gap: 0.1em;
   
    color:var(--theme-blue);
    text-transform: uppercase;

    ${props => {
        const colors = props.whiteBorders ? 'white' : 'var(--theme-blue)';
        return css`
        border: 2px solid ${colors};
        `}
      }
`;

export const createCalendarLink = (tilaisuus) => {
    const event = {
        title: tilaisuus["Otsikko"],
        start: new Date(tilaisuus["Tilaisuuden alkuaika"]),
        end:new Date(tilaisuus["Tilaisuuden loppuaika"]),
        location: tilaisuus.Sijainti.Paikka == "Muu paikka" ? undefined : `${tilaisuus.Sijainti.Paikka}, ${tilaisuus.Sijainti.Osoite.Katuosoite}, ${tilaisuus.Sijainti.Osoite.Postinumero}, ${tilaisuus.Sijainti.Osoite.Postitoimipaikka}`,
        description: tilaisuus.Sisalto
    };
    return (e: React.MouseEvent) =>{
        e.preventDefault();
        e.stopPropagation();
        window.open( cal.ical(event))
    } 
}
